<template>
  <v-row>
    <v-col cols="12" v-if="loaderRetrieve">
      <v-skeleton-loader type="table-heading, list-item-two-line" />
      <v-skeleton-loader class="mt-5" type="table-heading, list-item-two-line" />
      <v-skeleton-loader class="mt-5" type="table" />
    </v-col>
    <template v-else>
      <!-- unpaid payments alert -->
      <v-col cols="12" v-if="isDue">
        <v-alert class="my-0 body-2" dark dense prominent color="deep-orange" border="left" type="info" icon="mdi-update">
          <v-row align="center">
            <v-col class="grow">
              La organización se encuentra en <span class="font-weight-medium">proceso de suspensión</span> dado que tiene pagos vencidos.
            </v-col>
            <v-col class="shrink">
              <v-btn @click="$vuetify.goTo($refs.payments, { offset: 20 })" color="deep-orange darken-4">Pagar facturas pendientes</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
      <!-- end unpaid payments alert -->

      <!-- panel subscription --
      <v-col cols="12">
        <v-expansion-panels v-model="panelSubscription" multiple inset>
          <v-expansion-panel>
            <v-expansion-panel-header class="px-4 py-3">
              <v-row no-gutters align="center" justify="space-between">
                <v-col>
                  <span class="d-block grey-500--text subtitle-1 font-weight-medium">Suscripción</span>
                </v-col>
                <v-col class="text-right mr-4" v-if="!panelSubscription.length">
                  <span class="d-block body-2">Pay as you go <v-chip class="ml-2" color="lightBlue" small text-color="blue-500">Mensual</v-chip></span>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0">
              <v-row class="mx-n7 px-0">
                <v-col cols="3" class="ml-2">
                  <span class="d-block mb-1">Plan</span>
                  <span class="body-1 grey-500--text font-weight-medium">Pay as you go</span>
                  <v-chip class="ml-2" color="lightBlue" small text-color="blue-500">Mensual</v-chip>
                </v-col>
                <v-divider class="my-2" vertical />
                <v-col cols="3" class="ml-2">
                  <span class="d-block mb-1">Usuarios activos</span>
                  <div class="body-1 grey-500--text font-weight-medium">{{membersList.length}}</div>
                </v-col>
                <v-divider class="my-2" vertical />
                <v-col cols="3" class="ml-2">
                  <span class="d-block mb-1">Próxima emisión</span>
                  <div class="body-1 grey-500--text font-weight-medium">
                    <template v-if="nextBilling === today">Hoy</template>
                    <template v-else>{{nextBilling | date}}</template>
                  </div>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <!- end panel subscription -->

      <!-- panel sources -->
      <v-col cols="12">
        <v-expansion-panels v-model="panelSource" multiple inset>
          <v-expansion-panel>
            <v-expansion-panel-header class="px-4 py-3">
              <span class="d-block grey-500--text subtitle-1 font-weight-medium">Método de pago</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0">
              <v-row class="mx-n7 px-0">
                <v-col cols="12" class="ml-n1">
                  <v-list class="transparent" v-if="sourcesList.length">
                    <v-list-item v-for="(d, i) in sourcesList" :key="i">
                      <v-list-item-icon>
                        <img :src="require(`@/assets/appAsset-brand--${d.card.brand}.svg`)" :alt="d.card.brand" height="24">
                      </v-list-item-icon>
                      <v-list-item-title>
                        <span class="body-2 grey-500--text font-weight-medium">•••• •••• •••• {{d.card.last4}}</span>
                        <v-tooltip color="white-0" right max-width="280px" v-if="i === 0" transition="scale-transition">
                          <template v-slot:activator="{on}">
                            <v-chip class="ml-4" v-on="on" color="lightBlue" small text-color="blue-500">Predeterminado</v-chip>
                          </template>
                          <span>La usaremos por defecto cuando cobremos tu suscripción</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <template v-else>
                    <v-alert class="mx-4 mt-2 body-2" dark dense prominent color="indigo" border="left" type="info" icon="mdi-credit-card-remove">
                      <v-row align="center">
                        <v-col class="grow">
                          La organización no tiene una tarjeta registrada. En las próximas semanas será requerido registrar una tarjeta de crédito / débito en esta organización.
                        </v-col>
                        <v-col class="shrink">
                          <v-btn @click="intercomNewMessage('Hola, quiero registrar una tarjeta para esta organización.')" color="indigo darken-3">Registrar tarjeta ahora</v-btn>
                        </v-col>
                      </v-row>
                    </v-alert>
                  </template>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <!-- end panel sources -->

      <!-- panel payments -->
      <v-col cols="12">
        <v-card ref="payments">
          <v-toolbar color="transparent" flat height="55">
            <v-toolbar-title class="grey-500--text subtitle-1 font-weight-medium">Pagos</v-toolbar-title>
          </v-toolbar>
          <v-divider />
          <v-card-text class="pa-0">
            <v-row no-gutters>
              <v-col cols="12" v-if="paymentsList.length">
                <v-data-table :headers="headersPayments" :items="paymentsList" :items-per-page="10" mobile-breakpoint hide-default-footer disable-sort>
                  <template v-slot:[`item.documents`]="{item}">
                    <div class="py-2">
                      <span class="d-block">{{item.documents.document}}</span>
                      <span class="d-block grey-500--text font-weight-medium" v-if="item.status !== 'draft'">Nº {{item.documents.number}}</span>
                    </div>
                  </template>
                  <template v-slot:[`item.status`]="{item}">
                    <v-row align="center" justify="end">
                      <v-chip v-if="item.status === 'succeeded'" color="lightBlue" small text-color="blue-500">Pagado<v-icon class="mx-1" x-small>mdi-check</v-icon></v-chip>
                      <v-chip v-else-if="item.status === 'requires_payment_method'" color="defaultGrey" small text-color="grey-500">Incompleto</v-chip>
                      <v-chip v-else-if="item.status === 'failed'" color="defaultGrey" small text-color="grey-500">Fallido</v-chip>
                      <v-chip v-else-if="item.status === 'cancelled'" color="defaultGrey" small text-color="grey-500">Cancelado<v-icon class="mx-1" x-small>mdi-close</v-icon></v-chip>
                      <v-chip v-else-if="item.status === 'processing'" color="defaultGrey" small text-color="grey-500">Procesando</v-chip>
                    </v-row>
                  </template>
                  <template v-slot:[`item.date`]="{item}">
                    <span class="body-2">{{item.documents.date | date}}</span>
                  </template>
                  <template v-slot:[`item.due_date`]="{item}">
                    <span class="body-2 grey-500--text font-weight-medium" v-if="item.documents.due_date">{{item.documents.due_date | date}}</span>
                    <span class="font-italic text--disabled" v-else>No especificado</span>
                  </template>
                  <template v-slot:[`item.amount`]="{item}">
                    <div class="text-right">
                      <span class="body-2 grey-500--text font-weight-medium">{{item.amount | number(item.currency.rounded)}}</span> <span>{{item.currency.id}}</span>
                    </div>
                  </template>
                  <template v-slot:[`item.actions`]="{item}">
                    <div class="text-right" v-if="['requires_payment_method', 'failed'].includes(item.status)">
                      <v-btn :href="`${$PAY_BASE}/payments/${item.id}`" target="_blank" height="24" text color="blue-500" :ripple="false">Pagar<v-icon right>mdi-arrow-right</v-icon></v-btn>
                    </div>
                  </template>
                </v-data-table>
              </v-col>
              <v-col class="text-center py-5" v-else>
                <v-icon class="d-block" color="grey-500" size="70">mdi-receipt</v-icon>
                <span class="d-block subtitle-1">Aún no se han registrado pagos en la organización</span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions class="pa-4">
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-col>
      <!-- end panel payments -->
    </template>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import moment from 'moment'
export default {
  mixins: [
    GenericViewMixin
  ],
  data: () => ({
    headersPayments: [
      { text: 'Documento', value: 'documents' },
      { text: '', value: 'status' },
      { text: 'Fecha', value: 'date', align: 'end' },
      { text: 'Vencimiento', value: 'due_date', align: 'end' },
      { text: 'Total', value: 'amount', align: 'end' },
      { text: '', value: 'actions' }
    ],
    isDue: false,
    nextBilling: moment().add(1, 'M').startOf('month').format('YYYY-MM-DD'),
    panelSource: [0],
    panelSubscription: [0],
    loaderRetrieve: false
  }),
  computed: mapState({
    statesList: state => state.base.statesList,
    accountsDetail: state => state.accounts.accountsDetail,
    membersList: state => state.accounts.membersList,
    paymentsList: state => state.accounts.paymentsList,
    sourcesList: state => state.accounts.sourcesList
  }),
  created () {
    this.loaderRetrieve = true
    Promise.all([
      // this.$store.dispatch('accounts/LIST', {
      //   resource: 'billings/payments',
      //   query: {
      //     expand: 'documents',
      //     ordering: '-documents__due_date'
      //   }
      // }),
      // this.$store.dispatch('accounts/LIST', {
      //   resource: 'billings/sources',
      //   query: {
      //     gateway__usage: 'reusable',
      //     page_size: 1,
      //     status: 'chargeable'
      //   }
      // }),
      this.$store.dispatch('accounts/LIST', {
        resource: 'members',
        query: {
          is_active: 'True'
        }
      })
    ])
    .then(() => {
      this.isDue = this.paymentsList.filter(p => ['requires_payment_method', 'failed'].includes(p.status) && this.dateIsBefore(p.documents.due_date, this.today)).length
      this.loaderRetrieve = false
    })
    .catch((error) => {
      if (error.response) {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      }
    })
  }
}
</script>